import axiosConfig from './axiosConfig';
import { getDefaultPort } from '@/utils/apiRequestUtils';

const { baseURL } = axiosConfig.defaults;

const port = process.env.REACT_APP_SERVICE_PORT_FINANCE !== undefined
  ? process.env.REACT_APP_SERVICE_PORT_FINANCE
  : getDefaultPort(baseURL);

const financeModuleUrl = process.env.NODE_ENV === 'development'
  ? `${baseURL}:${port}/api`
  : `${baseURL}/finance/api`;

export const postToGetProjectFinance = projectID => axiosConfig
  .post(`${financeModuleUrl}/finance/projectFinance`, { projectID });

export const putNewProjectFinance = projectFinance => axiosConfig
  .put(`${financeModuleUrl}/finance/projectFinance`, projectFinance);

export const patchModifyProjectFinance = projectFinance => axiosConfig
  .patch(`${financeModuleUrl}/finance/projectFinance`, projectFinance);

export const deleteProjectFinance = projectID => axiosConfig
  .delete(`${financeModuleUrl}/finance/projectFinance`, { projectID });

export const postToGetProjectQuotations = config => axiosConfig
  .post(`${financeModuleUrl}/finance/projectQuotations`, config);

export const postToGetProjectQuotationsWithArrayOfProjects = config => axiosConfig
  .post(`${financeModuleUrl}/v2/finance/projectQuotations`, config);

export const putNewProjectQuotations = ProjectQuotation => axiosConfig
  .put(`${financeModuleUrl}/finance/projectQuotations`, ProjectQuotation);

export const deleteProjectQuotations = ProjectQuotationID => axiosConfig
  .delete(`${financeModuleUrl}/finance/projectQuotations`, { data: { ProjectQuotationID } });

export const postToGetProjectQuotationDetail = projectQuotationID => axiosConfig
  .post(`${financeModuleUrl}/finance/ProjectQuotationDetail`, { projectQuotationID });

export const putModifyProjectQuotationDetail = projectQuotation => axiosConfig
  .put(`${financeModuleUrl}/finance/ProjectQuotationDetail`, projectQuotation);

export const postToGetProjectInvoices = config => axiosConfig
  .post(`${financeModuleUrl}/finance/projectInvoices`, config);

export const putNewProjectInvoice = projectInvoice => axiosConfig
  .put(`${financeModuleUrl}/finance/projectInvoices`, projectInvoice);

export const deleteProjectInvoice = projectID => axiosConfig
  .delete(`${financeModuleUrl}/finance/projectInvoices`, { projectID });

export const postToGetProjectInvoicesDetail = projectID => axiosConfig
  .post(`${financeModuleUrl}/finance/projectInvoicesDetail`, { projectID });

export const postToGetProjectInvoicesDetailWithArrayOfProjects = projectIDs => axiosConfig
  .post(`${financeModuleUrl}/v2/finance/projectInvoicesDetail`, { projectID:[...projectIDs] });

export const putModifyProjectInvoicesDetail = projectInvoiceDetail => axiosConfig
  .put(`${financeModuleUrl}/finance/projectInvoicesDetail`, projectInvoiceDetail);

export const postToGetProjectPRServices = projectID => axiosConfig
  .post(`${financeModuleUrl}/finance/projectPRServices`, { projectID });

export const putNewProjectPRServices = projectPRServices => axiosConfig
  .put(`${financeModuleUrl}/finance/projectPRServices`, projectPRServices);

export const patchModifyProjectPRServices = projectPRServices => axiosConfig
  .patch(`${financeModuleUrl}/finance/projectPRServices`, projectPRServices);

export const deleteProjectPRServices = projectID => axiosConfig
  .delete(`${financeModuleUrl}/finance/projectPRServices`, { projectID });

export const postToGetDirectorBenefits = config => axiosConfig
  .post(`${financeModuleUrl}/finance/directorBenefits`, config);

export const postToGetExpectedIncome = config => axiosConfig
  .post(`${financeModuleUrl}/finance/expectedIncome`, config);

export const postToGetExportQuotationUrl = config => axiosConfig
  .post(`${financeModuleUrl}/finance/exportQuotation`, config);

export const putHRCostExcel = config => axiosConfig
  .put(`${financeModuleUrl}/finance/hrCost/excel`, config);

export const getHRCostExcel = data => axiosConfig
  .get(`${financeModuleUrl}/finance/hrCost/excel`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
    } ,
    responseType: 'blob',
    headers: {
      'Accept':  'application/octet-stream, */*',
    }
  });

export const deleteHRCostExcel = config => axiosConfig
  .post(`${financeModuleUrl}/finance/delHRCost/excel`, config);

export const getCompanyProfit = data => axiosConfig
  .get(`${financeModuleUrl}/finance/companyProfit`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
    }
  });

export const getCompanyProfitExcel = data => axiosConfig
  .get(`${financeModuleUrl}/finance/companyProfit/excel`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
    } ,
    responseType: 'blob',
    headers: {
      'Accept':  'application/octet-stream, */*',
    }
  });

export const getProjectProfit = data => axiosConfig
  .get(`${financeModuleUrl}/finance/projectProfit`, {
    params: { 
      companyTypeID: data.companyTypeID,
      projectID: data.projectID,
    }
  });

export const getProjectProfitExcel = data => axiosConfig
  .get(`${financeModuleUrl}/finance/projectProfit/excel`, {
    params: { 
      companyTypeID: data.companyTypeID,
      projectID: data.projectID,
    },
    responseType: 'blob',
    headers: {
      'Accept':  'application/octet-stream, */*',
    }
  });

export const getGroupProfitReport = data => axiosConfig
  .get(`${financeModuleUrl}/finance/groupProfit/report`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      director: data.director,
      statisticInterval: data.statisticInterval,
    }
  });

export const getGroupProfitReportExcel = data => axiosConfig
  .get(`${financeModuleUrl}/finance/groupProfit/report/excel`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      director: data.director,
      statisticInterval: data.statisticInterval,
    },
    responseType: 'blob',
    headers: {
      'Accept':  'application/octet-stream, */*',
    }
  });

export const getGroupProfitSales = data => axiosConfig
  .get(`${financeModuleUrl}/finance/groupProfit/sales`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      director: data.director,
      statisticInterval: data.statisticInterval,
    }
  });

export const getGroupProfitSalesExcel = data => axiosConfig
  .get(`${financeModuleUrl}/finance/groupProfit/sales/excel`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      director: data.director,
      statisticInterval: data.statisticInterval,
    },
    responseType: 'blob',
    headers: {
      'Accept':  'application/octet-stream, */*',
    }
  });

export const getGroupProfitProfit = data => axiosConfig
  .get(`${financeModuleUrl}/finance/groupProfit/profit`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      director: data.director,
      statisticInterval: data.statisticInterval,
    }
  });

export const getGroupProfitProfitExcel = data => axiosConfig
  .get(`${financeModuleUrl}/finance/groupProfit/profit/excel`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      director: data.director,
      statisticInterval: data.statisticInterval,
    },
    responseType: 'blob',
    headers: {
      'Accept':  'application/octet-stream, */*',
    }
  });

export const getGroupProfitProfitPie = data => axiosConfig
  .get(`${financeModuleUrl}/finance/groupProfit/profitPie`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      director: data.director,
      statisticInterval: data.statisticInterval,
    }
  });

export const getGroupProfitProfitPieExcel = data => axiosConfig
  .get(`${financeModuleUrl}/finance/groupProfit/profitPie/excel`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      director: data.director,
      statisticInterval: data.statisticInterval,
    },
    responseType: 'blob',
    headers: {
      'Accept':  'application/octet-stream, */*',
    }
});

export const getGroupProfitUnitGrossProfit = data => axiosConfig
  .get(`${financeModuleUrl}/finance/groupProfit/unitGrossProfit`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      director: data.director,
      statisticInterval: data.statisticInterval,
    }
  });

export const getGroupProfitUnitGrossProfitExcel = data => axiosConfig
  .get(`${financeModuleUrl}/finance/groupProfit/unitGrossProfit/excel`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      director: data.director,
      statisticInterval: data.statisticInterval,
    },
    responseType: 'blob',
    headers: {
      'Accept':  'application/octet-stream, */*',
    }
  });

export const getCustomerProfitReport = data => axiosConfig
  .get(`${financeModuleUrl}/finance/customerProfit/report`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      customerID: data.customerID,
    }
  });

export const getCustomerProfitReportExcel = data => axiosConfig
  .get(`${financeModuleUrl}/finance/customerProfit/report/excel`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      customerID: data.customerID,
    },
    responseType: 'blob',
    headers: {
      'Accept':  'application/octet-stream, */*',
    }
  });

export const getCustomerProfitSales = data => axiosConfig
  .get(`${financeModuleUrl}/finance/customerProfit/sales`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      customerID: data.customerID,
      statisticInterval: data.statisticInterval,
    }
  });

export const getCustomerProfitSalesExcel = data => axiosConfig
  .get(`${financeModuleUrl}/finance/customerProfit/sales/excel`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      customerID: data.customerID,
      statisticInterval: data.statisticInterval,
    },
    responseType: 'blob',
    headers: {
      'Accept':  'application/octet-stream, */*',
    }
  });

export const getCustomerProfitProfit = data => axiosConfig
  .get(`${financeModuleUrl}/finance/customerProfit/profit`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      customerID: data.customerID,
      statisticInterval: data.statisticInterval,
    }
  });

export const getCustomerProfitProfitExcel = data => axiosConfig
  .get(`${financeModuleUrl}/finance/customerProfit/profit/excel`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      customerID: data.customerID,
      statisticInterval: data.statisticInterval,
    },
    responseType: 'blob',
    headers: {
      'Accept':  'application/octet-stream, */*',
    }
  });

export const getCustomerProfitProfitPie = data => axiosConfig
  .get(`${financeModuleUrl}/finance/customerProfit/profitPie`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      customerID: data.customerID,
      statisticInterval: data.statisticInterval,
    }
  });

export const getCustomerProfitProfitPieExcel = data => axiosConfig
  .get(`${financeModuleUrl}/finance/customerProfit/profitPie/excel`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      customerID: data.customerID,
      statisticInterval: data.statisticInterval,
    },
    responseType: 'blob',
    headers: {
      'Accept':  'application/octet-stream, */*',
    }
  });

export const getCustomerProfitUnitGrossProfit = data => axiosConfig
  .get(`${financeModuleUrl}/finance/customerProfit/unitGrossProfit`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      customerID: data.customerID,
      statisticInterval: data.statisticInterval,
    }
  });

export const getCustomerProfitUnitGrossProfitExcel = data => axiosConfig
  .get(`${financeModuleUrl}/finance/customerProfit/unitGrossProfit/excel`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      customerID: data.customerID,
      statisticInterval: data.statisticInterval,
    },
    responseType: 'blob',
    headers: {
      'Accept':  'application/octet-stream, */*',
    }
  });

export const getGroupPreQuote = data => axiosConfig
  .get(`${financeModuleUrl}/finance/groupPreQuote`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      director: data.director,
    }
});

export const getGroupPreQuoteExcel = data => axiosConfig
  .get(`${financeModuleUrl}/finance/groupPreQuote/excel`, {
    params: { 
      startDate: data.startDate,
      endDate: data.endDate,
      companyTypeID: data.companyTypeID,
      director: data.director,
    },
    responseType: 'blob',
    headers: {
      'Accept':  'application/octet-stream, */*',
    }
});

export const postCreateLaborHealthInsuranceSetting = config => axiosConfig
  .post(`${financeModuleUrl}/finance/labor-health-insurance`, config);

export const getLaborHealthInsuranceApplyDates = (data) => axiosConfig
  .get(`${financeModuleUrl}/finance/labor-health-insurance/apply-dates`, { params: { 
    ...data
  }} 
);

export const getLaborHealthInsuranceSettings = (data) => axiosConfig
  .get(`${financeModuleUrl}/finance/labor-health-insurance`, { params: { 
    ...data
  }} 
);

// export const getLaborHealthInsuranceSettings = data => axiosConfig
//   .get(`${financeModuleUrl}/finance/labor-health-insurance/settings`, {
// });

export const patchUpdateLaborHealthInsuranceSetting = (id, data) => axiosConfig
  .patch(`${financeModuleUrl}/finance/labor-health-insurance/${id}`, data);

export const deleteLaborHealthInsuranceSetting = (id) => axiosConfig
  .delete(`${financeModuleUrl}/finance/labor-health-insurance/${id}`);

export const postCreateLeave = (config) => axiosConfig
  .post(`${financeModuleUrl}/finance/leave/`, config);

export const patchUpdateLeave = (id, config) => axiosConfig
  .patch(`${financeModuleUrl}/finance/leave/${id}`, config);

// export const getLeave = () => axiosConfig
//   .get(`${financeModuleUrl}/finance/leave/`);

export const getLeave = (parameters) => axiosConfig
  .get(`${financeModuleUrl}/finance/leave/`, { params: { 
    ...parameters
  }} 
);

export const getLeaveGiveHourById = (id) => axiosConfig
  .get(`${financeModuleUrl}/finance/leave/give-hour/${id}`);

export const postAddLeaveGiveHour = (id, config) => axiosConfig
  .post(`${financeModuleUrl}/finance/leave/give-hour/${id}`, {"giveHours": config});

export const deleteRemoveLeaveGiveHours = (config) => axiosConfig
  .delete(`${financeModuleUrl}/finance/leave/give-hour/`, {data: {'giveHourIds': config}} );

export const putSaveLeaveApprovalSetting = (id, config) => axiosConfig
  .put(`${financeModuleUrl}/finance/leave/${id}/approval/`, {"approvalSequence": config});

export const deleteLeaveApprovalSetting = (id) => axiosConfig
  .delete(`${financeModuleUrl}/finance/leave/${id}/approval/`);

export const getApprovalSettingByLeaveId = (id) => axiosConfig
  .get(`${financeModuleUrl}/finance/leave/${id}/approval/`);

export const postCreateLeaveGiveSetting = config => axiosConfig
  .post(`${financeModuleUrl}/finance/leave/give-settings`, config);

export const getLeaveGiveSetting = config => axiosConfig
  .get(`${financeModuleUrl}/finance/leave/give-settings/`, config);

export const deleteLeaveGiveSetting = (id) => axiosConfig
  .delete(`${financeModuleUrl}/finance/leave/give-settings/${id}`);

export const postCreateLeaveGiveHourSetting = config => axiosConfig
  .post(`${financeModuleUrl}/finance/leave/give-hour/settings`, config);

export const getCreateLeaveGiveHourSetting = config => axiosConfig
  .get(`${financeModuleUrl}/finance/leave/give-hour/settings`, config);

export const postCreateExtraPayments = config => axiosConfig
  .post(`${financeModuleUrl}/finance/extra-payments`, config);

export const getExtraPaymentsById = (id) => axiosConfig
  .get(`${financeModuleUrl}/finance/extra-payments/${id}`);

export const updateExtraPayments = (id, data) => axiosConfig
  .patch(`${financeModuleUrl}/finance/extra-payments/${id}`, data);

export const patchExtraPayments = (id, data) => axiosConfig
  .patch(`${financeModuleUrl}/finance/extra-payments/settings/${id}`, data);

export const getExtraPayments = (parameters) => axiosConfig
  .get(`${financeModuleUrl}/finance/extra-payments`, { params: { 
    ...parameters
  }} 
);

export const deleteExtraPayments = (id) => axiosConfig
  .delete(`${financeModuleUrl}/finance/extra-payments/${id}`);

export const postAddExtraPaymentsRecipients = config => axiosConfig
  .post(`${financeModuleUrl}/finance/extra-payments/recipients`, config);

export const deleteExtraPaymentsRecipients = (data) => axiosConfig
  .delete(`${financeModuleUrl}/finance/extra-payments/recipients`,  { data: data });

export const getRecipients = config => axiosConfig
  .get(`${financeModuleUrl}/finance/extra-payments/recipients`, config);

export const postCreateLeaveApplication = config => axiosConfig
  .post(`${financeModuleUrl}/finance/leave/applications`, config);

export const getLeaveApplications = (parameters) => axiosConfig
  .get(`${financeModuleUrl}/finance/leave/applications`, { params: { 
    ...parameters
  }} 
);

export const getLeaveApplicationById = (id) => axiosConfig
  .get(`${financeModuleUrl}/finance/leave/applications/${id}`);

export const patchModifyLeaveApplicationById = (id, parameters) => axiosConfig
  .patch(`${financeModuleUrl}/finance/leave/applications/${id}`, { 
    ...parameters
  }
);

export const deleteLeaveApplicationById = (id) => axiosConfig
  .delete(`${financeModuleUrl}/finance/leave/applications/${id}`);

export const postUpdateLeaveApplicationStatus = (id, status) => axiosConfig
  .post(`${financeModuleUrl}/finance/leave/applications/${id}/status`, { "status": status});

export const getLeaveApplicationsForApproval = () => axiosConfig
  .get(`${financeModuleUrl}/finance/leave/applications/for-approval`);

export const postCreatePaycheckStructure = (data) => axiosConfig
  .post(`${financeModuleUrl}/finance/paycheck-structure`, data);

export const patchUpdatePaycheckStructure = (id, data) => axiosConfig
  .patch(`${financeModuleUrl}/finance/paycheck-structure/${id}`, data);

export const deletePaycheckStructure = (id) => axiosConfig
  .delete(`${financeModuleUrl}/finance/paycheck-structure/${id}`);

export const getPaycheckStructures = (parameters) => axiosConfig
  .get(`${financeModuleUrl}/finance/paycheck-structure`, { params: { 
    ...parameters
  }}
);

export const getPaycheckStructureById = (id) => axiosConfig
  .get(`${financeModuleUrl}/finance/paycheck-structure/${id}`);

export const getPaycheckStructureByUserId = (parameters) => axiosConfig
  .get(`${financeModuleUrl}/finance/paycheck-structure/latest`, { params: { 
    ...parameters
  }}
);

export const getUserSalaries = (parameters) => axiosConfig
  .get(`${financeModuleUrl}/finance/salary`, { params: { 
    ...parameters
  }} 
);

// export const getUserSalaries = (month) => axiosConfig
//   .get(`${financeModuleUrl}/finance/salary`, { params: { 
//     month: month,
//   }} 
// );

export const postCalculateAllUsersSalaries = (bForCurrentMonth) => axiosConfig
  .post(`${financeModuleUrl}/finance/salary?forCurrentMonth=${bForCurrentMonth}`);

export const postCalculateUserSalaries = (userId) => axiosConfig
  .post(`${financeModuleUrl}/finance/user/${userId}/salary`);

export const getCalculateLeaveDurationForUser = (userId, data) => axiosConfig
  .get(`${financeModuleUrl}/finance/users/${userId}/leave-duration`, { params: data} );

export const getUserLeaveHours = (userId) => axiosConfig
  .get(`${financeModuleUrl}/finance/leave/hours/user/${userId}`);

export const getUserLeaveOptions = (userId, isCompensated) => axiosConfig
  .get(`${financeModuleUrl}/finance/users/${userId}/leave-options`, { params: { 
    isCompensated: isCompensated,
  }} 
);

export const getApprovedLeavesByDate = (date) => axiosConfig
  .get(`${financeModuleUrl}/finance/leaves/${date}/users`);

//Give in minutes
export const postIssueCompensatedLeave = (data) => axiosConfig
  .post(`${financeModuleUrl}/finance/leave/compensated`, data);

//Give in minutes
export const getCompensatedLeave = (data) => axiosConfig
  .get(`${financeModuleUrl}/finance/leave/compensated`, { params: data} );

export const getUserSalaryByMonth = (userId, month) => axiosConfig
  .get(`${financeModuleUrl}/finance/users/${userId}/salaries/${month}`
);

export const postInitiateProcessToGetLeaveOptions = (companyType) => axiosConfig
  .post(`${financeModuleUrl}/finance/users/leave-options?companyType=${companyType}`);

export const getLeaveOptionsStatusByJobId = (jobId) => axiosConfig
  .get(`${financeModuleUrl}/finance/users/leave-options/status/${jobId}`);

export const getLeaveOptionsResultsByJobId = (jobId) => axiosConfig
  .get(`${financeModuleUrl}/finance/users/leave-options/results/${jobId}`);

export const getLeaveOptionsResultsExcelByJobId = (jobId) => axiosConfig
  .get(`${financeModuleUrl}/finance/users/leave-options/results/${jobId}/excel`, {
    responseType: 'blob',
    headers: {
      'Accept':  'application/octet-stream, */*',
    }
  });

export const postLoadEIPHours = (specailLeaveId, data) => axiosConfig
  .post(`${financeModuleUrl}/finance/leave/${specailLeaveId}/load-eip-hours`, data);

export const postInitiateProcessToGetLeaveApplications = (companyType) => axiosConfig
  .post(`${financeModuleUrl}/finance/users/leave-applications?companyType=${companyType}`);

export const getLeaveApplicationsStatusByJobId = (jobId) => axiosConfig
  .get(`${financeModuleUrl}/finance/users/leave-applications/status/${jobId}`);

export const getLeaveApplicationsResultsByJobId = (jobId) => axiosConfig
  .get(`${financeModuleUrl}/finance/users/leave-applications/results/${jobId}`);