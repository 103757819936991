import React from 'react';
import styled from 'styled-components';

import Checkbox from '@/components/UI/Checkbox';
import { TableHead, TableRow, ThItem } from '@/component-style/Table';

const THead = styled(TableHead)`
  min-width: ${ props => props.showCheckbox
    ? '1320px'
    : '1240px'
  };
`;

const Th80 = styled(ThItem)`
  width: 80px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const Th160 = styled(ThItem)`
  width: 160px;
`;

const Th240 = styled(ThItem)`
  width: 240px;
`;

const Header = props => {
  
  return (
    <THead
    >
      <TableRow>
        <Th120>人員</Th120>
        <Th240>休假開始時間</Th240>
        <Th240>休假結束時間</Th240>
      </TableRow>
    </THead>
  );
};

export default Header;
