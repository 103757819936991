import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { postToGetUserInfo } from '@/api/authService';
import { postToGetProjectDetail } from '@/api/projectService';

import {
  putModifyWorkingHours,
  postToGetWorkingHours,
  postToGetWorkingHoursDetail,
  postWorkingHoursSetApprovementStatus,
} from '@/api/attendanceService';

import { FETCH_WORKINGHOURS_TYPE_OPTIONS } from '@/store/actions/srpOptionsAction';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupExecuteConfirm from '@/components/UI/Popup/PopupExecuteConfirm';
import TableOvertimeApprovements from '@/components/Table/Attendance/TableOvertimeApprovements';

import { integerConverter } from '@/utils/textUtils';
import { formatTime } from '@/utils/timeUtils';

const searchConfig = {
  isOverTime: true,
  // approvementStatus: 'unapproved',
  userIDs: [],
  amount: 10,
};

const OvertimeApprovement = () => {
  const dispatch = useDispatch();
  const userID = useSelector(state => state.login.token);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [overtimeRecords, setOvertimeRecords] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showAgreeAllPopup, setShowAgreeAllPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    fetchUnapprovedOvertimes(page);
  };

  const updateValidTimeHandler = (index, value) => {
    const updatedList = [ ...overtimeRecords ];
    overtimeRecords[index].overtimeType === 'freeTime'
      ? overtimeRecords[index].freeTimeMins = value
      : overtimeRecords[index].extraPayMins = value;

    setOvertimeRecords([...updatedList]);
  };

  const closeSuccessPopupHandler = () => {
    setShowSaveSuccessPopup(false);
    setCurrentPageHandler(1);
  };

  const agreeHandler = async (index) => {
    setShowLoadingPopup(true);
    const { workingHoursID, freeTimeMins, extraPayMins } = overtimeRecords[index];

    try {
      const { data } = await postToGetWorkingHoursDetail(workingHoursID);
      data.approvementUserID === '' && delete data.approvementUserID;
      delete data.startTime;
      delete data.endTime;
      await putModifyWorkingHours({
        ...data,
        freeTimeMins: integerConverter(freeTimeMins),
        extraPayMins: integerConverter(extraPayMins),
        approvementStatus: 'approved',
        approvementDate: formatTime(Date.now(), 'YYYY-MM-DD'),
        approvementUserID: userID,
      });
      await postWorkingHoursSetApprovementStatus({
        workingHoursID: data.workingHoursID,
        approvementStatus: 'approved',
        approvementDate: formatTime(Date.now(), 'YYYY-MM-DD'),
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '審核失敗', err }));
      console.log(err);
    }
  };

  const disagreeHandler = async (index) => {
    setShowLoadingPopup(true);
    const { workingHoursID, freeTimeMins, extraPayMins } = overtimeRecords[index];

    try {
      const { data } = await postToGetWorkingHoursDetail(workingHoursID);
      data.approvementUserID === '' && delete data.approvementUserID;
      delete data.startTime;
      delete data.endTime;
      await putModifyWorkingHours({
        ...data,
        freeTimeMins: integerConverter(freeTimeMins),
        extraPayMins: integerConverter(extraPayMins),
        approvementStatus: 'disagree',
      });
      await postWorkingHoursSetApprovementStatus({
        workingHoursID: data.workingHoursID,
        approvementStatus: 'disagree',
        approvementDate: formatTime(Date.now(), 'YYYY-MM-DD'),
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '審核失敗', err }));
      console.log(err);
    }
  };

  const agreeAllHandler = async () => {
    setShowAgreeAllPopup(false);
    setShowLoadingPopup(true);

    try {

      for (let i = 0 ; i < overtimeRecords.length; i += 1) {
        const { workingHoursID, freeTimeMins, extraPayMins } = overtimeRecords[i];
        const { data } = await postToGetWorkingHoursDetail(workingHoursID);
        data.approvementUserID === '' && delete data.approvementUserID;
        delete data.startTime;
        delete data.endTime;
        await putModifyWorkingHours({
          ...data,
          freeTimeMins: integerConverter(freeTimeMins),
          extraPayMins: integerConverter(extraPayMins),
          approvementStatus: 'approved',
          approvementDate: formatTime(Date.now(), 'YYYY-MM-DD'),
          approvementUserID: userID,
        });
        await postWorkingHoursSetApprovementStatus({
          workingHoursID: data.workingHoursID,
          approvementStatus: 'approved',
          approvementDate: formatTime(Date.now(), 'YYYY-MM-DD'),
        });
      }
      
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '審核失敗', err }));
      console.log(err);
    }
  };

  const fetchUnapprovedOvertimes = async (page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      const { data } = await postToGetWorkingHours({
        ...searchConfig, page,
        "forApprove": true,
        // "membersOnly": true,
      });

      const { workingHours, totalCount } = data;
      const overtimeRecords = [];
      // console.log("overtiemApprovement.js fetchUnapprovedOvertimes workingHours:"  + JSON.stringify(workingHours));
      // var filtered = workingHours.filter(function(value, index, arr) { 
      //   return value.projectID !== "01EX63N6VG5H0FEWA2WBFCEM0J";
      // });

      // console.log("overtiemApprovement.js fetchUnapprovedOvertimes filtered:"  + JSON.stringify(filtered));

      for (let i = 0; i < workingHours.length; i += 1 ) {
        const { projectID, userID } = workingHours[i];
        const projectInfo = await postToGetProjectDetail(projectID);
        const userInfo = await postToGetUserInfo(userID);
        const { projectCode, projectName } = projectInfo.data;
        const { name } = userInfo.data;
        overtimeRecords.push({
          ...workingHours[i],
          username: name,
          projectCode,
          projectName,
        });
      }

      setOvertimeRecords([ ...overtimeRecords ]);
      setTotalCount(totalCount || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const initUnapprovedOvertimes = useCallback(
    fetchUnapprovedOvertimes, []);

  const initOptions = useCallback(() => {
    dispatch(FETCH_WORKINGHOURS_TYPE_OPTIONS());
  }, [dispatch]);

  useEffect(() => {
    initOptions();
    initUnapprovedOvertimes();
  }, [initOptions, initUnapprovedOvertimes]);

  return (
    <MainContent>
      <TableOvertimeApprovements
        overtimeRecords = { overtimeRecords }
        totalCount = { totalCount }
        currentPage = { currentPage }
        agreeHandler = { agreeHandler }
        disagreeHandler = { disagreeHandler }
        updateValidTimeHandler = { updateValidTimeHandler }
        agreeAllHandler = { () => { setShowAgreeAllPopup(true) } }
        pageChangedHandler = { value => {
          setCurrentPageHandler(value);
        }}
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showAgreeAllPopup &&
          <PopupExecuteConfirm
            title = "同意全部加班申請"
            text = "確定同意全部加班申請？"
            confirmHandler = { agreeAllHandler }
            cancelHandler = { () => {
              setShowAgreeAllPopup(false);
            }}
          />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "審核成功"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  )
};

export default OvertimeApprovement;
