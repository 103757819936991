import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { TdItem, TableRow } from '@/component-style/Table';
import { ButtonGoDetail } from '@/component-style/Button';

import { getOptionDesc } from '@/utils/textUtils';

import { decimalCommaConverter } from '@/utils/textUtils';

const TrWrapper = styled(TableRow)`
  border-bottom: solid 2px #f2f2f2;
  box-sizing: border-box;
  min-width: 1120px;

  &:last-child {
    border-bottom: none;
  }
`;

const Td80 = styled(TdItem)`
  width: 80px;
`;

const Td120 = styled(TdItem)`
  width: 120px;
`;

const Td160 = styled(TdItem)`
  width: 160px;
`;

const Td240 = styled(TdItem)`
  width: 240px;
`;

const Item = props => {
  const { wage } = props;
  console.log(`TablePersonnelWageList Item.js JSON.stringify(wage):${JSON.stringify(wage)}`);
  // const { user, objectID } = expense;
  // const vendorIDs = useSelector(state => state.srpOptions.vendorIDOptions);
  // const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const companyTypeOptions = useSelector(state => state.srpOptions.companyTypeOptions);

  // const username = getOptionDesc(memberOptions, user);

  // const objectName = expense.object === 'user'
  //   ? getOptionDesc(memberOptions, objectID)
  //   : getOptionDesc(vendorIDs, objectID);

  return (
    <TrWrapper>
      <Td120>{ getOptionDesc(companyTypeOptions, wage.companyType) }</Td120>
      <Td120>{ wage.username }</Td120>
      <Td120>{ wage.basicSalary == null ? '' : decimalCommaConverter(wage.basicSalary) }</Td120>
      <Td120>{ wage.laborInsurance == null ? '' : decimalCommaConverter(wage.laborInsurance) }</Td120>
      <Td120>{ wage.healthInsurance == null ? '' : decimalCommaConverter(wage.healthInsurance) }</Td120>
      <Td120>{ wage.healthInsurance == null ? '' : decimalCommaConverter(wage.healthInsuranceDependent) }</Td120>
      <Td120>{ wage.leave == null ? '' : decimalCommaConverter(wage.leave) }</Td120>
      <Td120>{ wage.overtimePay == null ? '' : decimalCommaConverter(wage.overtimePay) }</Td120>
      <Td120>{ wage.extraPay == null ? '' : decimalCommaConverter(wage.extraPay) }</Td120>
      <Td120>{ wage.totalSalary == null ? '' : decimalCommaConverter(wage.totalSalary) }</Td120>
      <Td120>{ wage.pension == null ? '' : decimalCommaConverter(wage.pension) }</Td120>
      <Td120>{ wage.selfPayPension == null ? '' : decimalCommaConverter(wage.selfPayPension) }</Td120>
      <Td80>
        <ButtonGoDetail
          disabled = { props.disableDetailPage }
          onClick = { () => { props.goDetailHandler(wage.userId) } }
        >...
        </ButtonGoDetail>
      </Td80>
    </TrWrapper>
  );
};

export default Item;
